<script>
import { onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import SubTopNavBar from "@/common/components/SubTopNavBar.vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import PlaylistTable from "@/common/components/PlaylistTable.vue";
import { getCuratorPlaylistByChartmetricCuratorID } from "@/api/playlists.api";
import { useRoute } from "vue-router";
import {
  getCuratorProfileByChartmetricCuratorID,
  getCuratorsFanmetricByChartmetricCuratorIDSourceAndField,
  saveCuratorDetailByChartmetricCuratorID,
} from "@/api/curators.api";
import {
  capitalizeEveryWordFirstLetter,
  capitalizeFirstLetter,
  checkIsValueAvailableOrNot,
  getPlatformColor,
  getTagName,
} from "@/utils/staticHelper";
import CardToolbar from "@/common/components/CardToolbar.vue";
import LineAndAreaChart from "@/common/components/LineAndAreaChart.vue";
import CuratorContacts from "@/curators/components/CuratorContacts.vue";

export default {
  name: "CuratorDetail",
  methods: { capitalizeFirstLetter, getTagName, checkIsValueAvailableOrNot },
  components: {
    LineAndAreaChart,
    SubTopNavBar,
    KpiCardV2,
    PlaylistTable,
    CardToolbar,
    CuratorContacts,
  },
  setup() {
    const loading = ref(false);
    const fanmetricLoading = ref(false);
    const route = useRoute();
    const playlistLoading = ref(false);
    const updateLoading = ref(false);
    const curatorDetail = ref({});
    const chartmetricContacts = ref([]);
    const fanmetricSources = ref([
      {
        id: 1,
        platform: "spotify",
        source: "facebook",
        field: "followers",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 2,
        platform: "spotify",
        source: "facebook",
        field: "likes",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 3,
        platform: "spotify",
        source: "facebook",
        field: "talks",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 4,
        platform: "spotify",
        source: "instagram",
        field: "followers",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 5,
        platform: "spotify",
        source: "spotify",
        field: "followers",
        dataFound: false,
        categories: [],
        series: [],
      },
    ]);
    const tabMenuList = ref([
      {
        id: "curator_statistics",
        name: "Statistics",
        icon: "/media/svg/playlist.svg",
        alt: "statistics",
      },
      {
        id: "curator_playlists",
        name: "Playlists",
        icon: "/media/svg/playlist.svg",
        alt: "statistics",
      },
      {
        id: "curator_fanbase",
        name: "Fanbase",
        icon: "/media/svg/playlist.svg",
        alt: "fanbase",
      },
      {
        id: "curator_contacts",
        name: "Contacts",
        icon: "/media/svg/playlist.svg",
        alt: "contacts",
      },
    ]);
    const tableData = ref([]);
    onMounted(() => {
      document.title = "Curator";
      getCuratorProfile();
      getCuratorPlaylists();
      fetchFanmetricPlatforms();
    });

    const getCuratorProfile = async () => {
      try {
        loading.value = true;
        const { data } = await getCuratorProfileByChartmetricCuratorID(
          route.params.chartmetricCuratorID
        );
        curatorDetail.value =
          data && data.data && data.data.chartmetricMeta
            ? data.data.chartmetricMeta
            : {};

        document.title =
          curatorDetail.value && curatorDetail.value.name
            ? "Curator | " + curatorDetail.value.name
            : "Curator";

        chartmetricContacts.value =
          data && data.data && data.data.chartmetricContacts
            ? data.data.chartmetricContacts
            : {};
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const getCuratorPlaylists = async () => {
      try {
        playlistLoading.value = true;
        const { data } = await getCuratorPlaylistByChartmetricCuratorID(
          route.params.chartmetricCuratorID
        );
        tableData.value = data && data.data ? data.data : [];
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching playlists",
          type: "error",
        });
      } finally {
        playlistLoading.value = false;
      }
    };
    const fetchFanmetricPlatforms = async () => {
      try {
        fanmetricLoading.value = true;
        let promises = [];
        Object.values(fanmetricSources.value).forEach((platform) => {
          promises.push(
            new Promise((resolve) => {
              getCuratorsFanmetricByChartmetricCuratorIDSourceAndField(
                route.params.chartmetricCuratorID,
                platform.source,
                platform.field
              )
                .then(({ data }) => {
                  if (data.data && data.data.length) {
                    const xAxis = [];
                    const yAxis = [];
                    platform.series = [];
                    platform.categories = [];
                    platform.dataFound = true;
                    for (const follower of data.data) {
                      xAxis.push(follower.timestp);
                      yAxis.push(follower.value);
                    }
                    platform.categories = xAxis;
                    platform.series.push({
                      name: capitalizeEveryWordFirstLetter(platform.field),
                      data: yAxis,
                      color: getPlatformColor(platform.source),
                    });
                  } else {
                    platform.dataFound = false;
                  }
                  data.data = [];
                })
                .finally(() => {
                  resolve("");
                });
            })
          );
        });

        await Promise.all(promises);
      } catch (e) {
        console.log(e);
      } finally {
        fanmetricLoading.value = false;
      }
    };
    const updateDetail = async () => {
      try {
        updateLoading.value = true;
        await saveCuratorDetailByChartmetricCuratorID(
          route.params.chartmetricCuratorID,
          true
        );
        ElNotification({
          title: "Success",
          message: "Request sent for updating curator details",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in updating curator details",
          type: "error",
        });
      } finally {
        updateLoading.value = false;
      }
    };
    return {
      loading,
      tabMenuList,
      updateLoading,
      playlistLoading,
      tableData,
      curatorDetail,
      chartmetricContacts,
      fanmetricSources,
      fanmetricLoading,
      updateDetail,
    };
  },
};
</script>
<template>
  <div
    id="curator_detail"
    class="d-flex justify-content-start"
    v-loading.fullscreen.lock="loading"
  >
    <div class="curator-main-content w-100">
      <div class="d-flex page-heading">
        Curator :
        {{ curatorDetail && curatorDetail.name ? curatorDetail.name : "" }}
      </div>
      <sub-top-nav-bar
        :tabs-list="tabMenuList"
        current-active-tab="#curator_statistics"
        main-id="curator_menu_list"
        :sub-list="[]"
        :scroll-to-element-margin-main="80"
        sub-id="artist_sub_id"
        :have-sub-list="false"
        :update-button="true"
        @updateDetail="updateDetail"
        :loading="updateLoading"
      />
      <div class="row mt-8">
        <div id="curator_statistics">
          <div class="artist-content-heading">Statistics</div>
          <div class="mt-2 mb-3 mb-xl-5">
            <el-row :gutter="12">
              <el-col
                class="margin-bottom-12"
                :lg="8"
                :md="8"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot('object', curatorDetail, 'name')
                  "
                  :text="'Curator Name'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="8"
                :md="8"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    getTagName(
                      curatorDetail && curatorDetail.tags
                        ? curatorDetail.tags
                        : [],
                      'name'
                    )
                  "
                  :text="'Genres'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="8"
                :md="8"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      curatorDetail && curatorDetail.cm_statistics
                        ? curatorDetail.cm_statistics
                        : {},
                      'num_sp_playlists'
                    )
                  "
                  :text="'Playlists'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="8"
                :md="8"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      curatorDetail && curatorDetail.cm_statistics
                        ? curatorDetail.cm_statistics
                        : {},
                      'sp_playlist_total_reach'
                    )
                  "
                  :text="'Playlist Reach'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="8"
                :md="8"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      curatorDetail && curatorDetail.cm_statistics
                        ? curatorDetail.cm_statistics
                        : {},
                      'max_sp_playlist_followers'
                    )
                  "
                  :text="'Max Playlist Reach'"
                />
              </el-col>
              <el-col
                class="margin-bottom-12"
                :lg="8"
                :md="12"
                :sm="12"
                :xs="24"
              >
                <kpi-card-v2
                  :title="
                    checkIsValueAvailableOrNot(
                      'object',
                      curatorDetail && curatorDetail.cm_statistics
                        ? curatorDetail.cm_statistics
                        : {},
                      'sp_followers'
                    )
                  "
                  :text="'Followers'"
                />
              </el-col>
            </el-row>
          </div>
        </div>
        <div id="curator_playlists">
          <div class="artist-content-heading">Playlists</div>
          <div class="mt-2">
            <PlaylistTable
              :playlist-data="tableData"
              :playlist-loading="playlistLoading"
              :card-title="''"
            />
          </div>
        </div>
        <div id="curator_fanbase">
          <div class="artist-content-heading">Fanbase</div>
          <div class="mt-2">
            <CardToolbar
              :title="''"
              :show-toolbar-buttons="false"
              :margin="true"
              :fontStyle="true"
              :padding="true"
              :padding-left="false"
              :shadow-class="true"
              :show-header="false"
            >
              <template v-slot:body>
                <el-row :gutter="24" v-if="!fanmetricLoading">
                  <el-col
                    v-for="platform in fanmetricSources"
                    :key="platform"
                    :xs="24"
                    :md="24"
                    :lg="24"
                  >
                    <div class="p-2" v-if="platform.dataFound">
                      <div class="fanbase-line-chart">
                        <div class="card-title d-flex align-items-center">
                          <el-image
                            class="icon"
                            :src="
                              '/media/svg/social-logos/' +
                              platform.source +
                              '.svg'
                            "
                          />
                          <div class="card-label fw-bolder m-lg-4">
                            {{ platform.field.toUpperCase() }}
                          </div>
                        </div>
                        <LineAndAreaChart
                          :chart-height="350"
                          :categories="platform.categories"
                          :chart-series="platform.series"
                          :chart-type="'area'"
                          :chart-stroke="{
                            curve: 'smooth',
                            show: true,
                            width: [3, 1],
                            dashArray: [0, 8],
                          }"
                          :tooltip-x-format="'MMM DD, YYYY'"
                        />
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </template>
            </CardToolbar>
          </div>
        </div>
        <div id="curator_contacts">
          <div class="artist-content-heading">Curator Contacts</div>
          <CuratorContacts
            v-if="chartmetricContacts.length"
            :contacts="chartmetricContacts"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.fanbase-line-chart {
  .icon {
    height: 40px;
    width: 40px;
  }
}
</style>
