<script>
import { ref } from "vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import { tableCellClassName, tableRowClassName } from "@/utils/staticHelper";
import { EditPen } from "@element-plus/icons-vue";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRoute } from "vue-router";
import { updateCuratorContactsByChartmetricCuratorID } from "@/api/curators.api";
import { ElNotification } from "element-plus";
export default {
  name: "CuratorContacts",
  methods: { tableCellClassName, tableRowClassName },
  components: { EditPen, CardToolbar, ErrorMessage, Field, Form },
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const loading = ref(false);
    const route = useRoute();
    const formRef = ref(null);
    const submitButtonRef = ref(null);
    const closeModalCloseButton = ref(null);
    const tableData = ref(props.contacts ? props.contacts : []);
    const contact = ref({ domain: "", url: "", type: "custom" });
    const modalType = ref("add");

    const validationSchema = Yup.object().shape({
      domain: Yup.string().required().label("Domain Name"),
      url: Yup.string().required().label("Domain Url"),
    });

    const resetData = () => {
      contact.value = { domain: "", url: "", type: "custom" };
    };
    const openModal = (type, obj) => {
      modalType.value = type;
      if (modalType.value === "edit") {
        contact.value = obj;
      } else {
        resetData();
      }
    };

    const submitContact = async ($event) => {
      $event.preventDefault();
      try {
        loading.value = true;
        //Disable button
        submitButtonRef.value.disabled = true;
        // Activate indicator
        submitButtonRef.value.setAttribute("data-kt-indicator", "on");
        if (modalType.value === "add") {
          tableData.value.push(contact.value);
        }
        const payload = {
          chartmetricContacts: tableData.value,
        };
        await updateCuratorContactsByChartmetricCuratorID(
          route.params.chartmetricCuratorID,
          payload
        );
        ElNotification({
          title: "Success",
          message: "Contacts Updated Successfully",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in updating contacts",
          type: "error",
        });
      } finally {
        loading.value = false;
        closeModalCloseButton.value.click();
        submitButtonRef.value.removeAttribute("data-kt-indicator");
        submitButtonRef.value.disabled = false;
      }
    };

    return {
      loading,
      tableData,
      contact,
      validationSchema,
      formRef,
      submitButtonRef,
      closeModalCloseButton,
      resetData,
      openModal,
      submitContact,
    };
  },
};
</script>
<template>
  <CardToolbar
    :title="''"
    :show-toolbar-buttons="true"
    :margin="true"
    :fontStyle="true"
    :padding="false"
    :padding-left="false"
    :shadow-class="true"
    :show-header="true"
  >
    <template v-slot:toolbar>
      <button
        class="
          btn btn-sm btn-color-muted btn-active btn-active-primary
          px-4
          me-1
          active
        "
        data-bs-toggle="modal"
        data-bs-target="#curator_contact_modal"
        @click="openModal('add', {})"
      >
        Add Contact
      </button>
    </template>
    <template v-slot:body>
      <el-table
        v-loading="loading"
        :fit="true"
        size="small"
        :data="tableData"
        tooltip-effect="light"
        stripe
        style="width: 100%"
        header-cell-class-name="table-header-text"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column label="Domain">
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.domain ? scope.row.domain : ''"
            >
              {{ scope.row.domain ? scope.row.domain : "" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Social Links">
          <template #default="scope">
            <a
              :href="scope.row.url ? scope.row.url : ''"
              target="_blank"
              class="link theme-v2-color"
            >
              {{ scope.row.url ? scope.row.url : "" }}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="" align="right">
          <template #default="scope">
            <el-icon v-if="scope.row.type === 'custom'"
              ><EditPen
                class="cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#curator_contact_modal"
                @click="openModal('edit', scope.row)"
            /></el-icon>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </CardToolbar>

  <div
    class="modal fade"
    id="curator_contact_modal"
    tabindex="-1"
    aria-hidden="true"
    role="dialog"
    aria-labelledby="curatorContactModal"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_create_api_key_header">
          <h2>Add Contact</h2>
        </div>
        <Form ref="formRef" class="form" :validation-schema="validationSchema">
          <div class="modal-body py-2 px-lg-17">
            <div
              class="scroll-y me-n7 pe-7 pt-10 pb-10"
              id="kt_modal_create_api_key_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
              data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
              data-kt-scroll-offset="300px"
            >
              <div class="mb-5 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Domain Name</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  v-model="contact.domain"
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Domain Name"
                  name="domain"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="domain" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <div class="mb-5 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Domain Url</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  v-model="contact.url"
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Domain Url"
                  name="url"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="url" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
            </div>
          </div>
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              ref="closeModalCloseButton"
              type="reset"
              class="btn btn-white me-3"
              data-bs-toggle="modal"
              data-bs-target="#curator_contact_modal"
              @click="resetData"
            >
              Close
            </button>
            <button
              ref="submitButtonRef"
              type="submit"
              class="btn btn-primary"
              @click="submitContact"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.link {
  text-decoration: none;
}
.link:hover {
  text-decoration: underline !important;
  color: #409eff !important;
}
</style>
