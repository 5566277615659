<script>
import { ref, watch } from "vue";
import {
  formatNumberIntoHumanizeForm,
  paginate,
  routeToPage,
  sortArray,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import moment from "moment/moment";
import CardToolbar from "@/common/components/CardToolbar.vue";
import { Position } from "@element-plus/icons-vue";
import { TypeEnums } from "@/common/enums/typeEnums";

export default {
  name: "PlaylistTable",
  computed: {
    TypeEnums() {
      return TypeEnums;
    },
  },
  methods: {
    formatNumberIntoHumanizeForm,
    routeToPage,
    tableCellClassName,
    tableRowClassName,
  },
  components: { Position, CardToolbar },
  props: {
    playlistData: {
      type: Array,
      required: true,
    },
    playlistLoading: {
      type: Boolean,
      default: false,
    },
    cardTitle: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const loading = ref(true);
    const tableData = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const tableDataWithOutPagination = ref([]);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
      sortBy: "followers",
      sortOrder: "desc",
    });

    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        tableDataWithOutPagination.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(tableDataWithOutPagination.value))
        );
      }
      paginationChanged();
    };

    const paginationChanged = () => {
      tableData.value = paginate(
        tableDataWithOutPagination.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
    };

    watch(
      () => props.playlistData,
      (value) => {
        if (value.length > 0) {
          tableDataWithOutPagination.value = value;
          localPagination.value.total = tableDataWithOutPagination.value.length;
          paginationChanged();
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      () => props.playlistLoading,
      (value) => {
        loading.value = value;
      },
      { immediate: true, deep: true }
    );

    return {
      loading,
      tableData,
      sortBy,
      sortDir,
      tableDataWithOutPagination,
      localPagination,
      paginationChanged,
      sortResults,
      moment,
    };
  },
};
</script>
<template>
  <CardToolbar
    :title="cardTitle"
    :show-toolbar-buttons="false"
    :margin="true"
    :fontStyle="true"
    :padding="cardTitle === ''"
    :padding-left="false"
    :shadow-class="true"
    :show-header="cardTitle !== ''"
  >
    <template v-slot:body>
      <el-table
        @sort-change="sortResults"
        v-loading="loading"
        :fit="true"
        size="small"
        :data="tableData"
        tooltip-effect="light"
        stripe
        style="width: 100%"
        header-cell-class-name="table-header-text"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column
          class-name="cursor-pointer"
          prop="name"
          label="Name"
          sortable="custom"
          fixed
          width="300"
        >
          <template #default="scope">
            <p
              @click="
                routeToPage(
                  `playlist-detail/${scope.row.curatorPlaylistID}`,
                  TypeEnums.PLAYLIST,
                  scope.row.curatorPlaylistID
                )
              "
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="
                scope.row.playlistName ? scope.row.playlistName : 'N/A'
              "
            >
              {{ scope.row.playlistName ? scope.row.playlistName : "N/A" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="ownerName"
          label="Curator"
          sortable="custom"
          width="200"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.ownerName ? scope.row.ownerName : 'N/A'"
            >
              {{ scope.row.ownerName ? scope.row.ownerName : "N/A" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="followers"
          label="Followers"
          sortable="custom"
          width="170"
        >
          <template #default="scope">
            {{
              scope.row.followers
                ? formatNumberIntoHumanizeForm(scope.row.followers)
                : 0
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="code2"
          label="Country"
          sortable="custom"
          width="170"
        >
          <template #default="scope">
            {{ scope.row.country ? scope.row.country : "N/A" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="catalog"
          label="catalog"
          sortable="custom"
          width="170"
        >
          <template #default="scope">
            {{ scope.row.catalog ? scope.row.catalog : "N/A" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="fdiffMonth"
          label="Followers Diff Last Month"
          sortable="custom"
          width="200"
        >
          <template #default="scope">
            {{
              scope.row.fdiffMonth
                ? formatNumberIntoHumanizeForm(scope.row.fdiffMonth)
                : 0
            }}
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="Genres" width="400">
          <template #default="scope">
            <el-tag
              v-for="item in scope.row.tags"
              :key="item"
              size="small"
              class="mx-1"
              effect="dark"
            >
              {{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="" align="right" width="50">
          <template #default="scope">
            <el-tooltip
              class="box-item"
              effect="dark"
              content="Playlist Detail Page"
              placement="left-start"
            >
              <el-icon
                @click="
                  routeToPage(
                    `playlist-detail/${scope.row.curatorPlaylistID}`,
                    TypeEnums.PLAYLIST,
                    scope.row.curatorPlaylistID
                  )
                "
                class="cursor-pointer"
                ><Position
              /></el-icon>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-7 mb-7"
        v-model:currentPage="localPagination.offset"
        v-model:page-size="localPagination.limit"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="localPagination.limit"
        :small="true"
        layout="total, sizes, prev, pager, next"
        :total="localPagination.total"
        @size-change="paginationChanged"
        @current-change="paginationChanged"
      >
      </el-pagination>
    </template>
  </CardToolbar>
</template>
